const DEFAULT_WIDTH = 1920;
const DEFAULT_QUALITY = 70;

export function getImage(src, {modifiers, baseURL} = {}) {
    const {width, quality, format} = modifiers

    let url = src;

    if (format) {
        url += `.${format}`;
    }

    if (!/\.[a-z0-9]{3,4}$/i.test(url)) {
        url += ".webp";
    }

    const query = new URLSearchParams();

    query.append('w', width ?? DEFAULT_WIDTH);
    query.append('q', quality || DEFAULT_QUALITY);

    url += `?${query.toString()}`;

    return {url};
}
